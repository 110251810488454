<script>
export default {
  name: 'ProjectTeamSection',
  props: {
    activeContracts: {
      type: Array,
      required: true,
    },
    managers: {
      type: Array,
      required: true,
    },
    docControllers: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'search',
    'update:modelValue',
  ],
  data () {
    return {
      managersData: {},
      docControllersData: {},
      activeContractsData: {},
    }
  },
  watch: {
    managers: {
      handler() {
        this.managersData = this.managers
      },
      immediate: true,
    },
    docControllers: {
      handler() {
        this.docControllersData = this.docControllers
      },
      immediate: true,
    },
    activeContracts: {
      handler() {
        this.activeContractsData = this.activeContracts
      },
      immediate: true,
    },
  },
  methods: {
    onSearch(text) {
      this.$emit('search', text)
    },
    getItemDisplay (item) {
      return `${item.fullName} (${item.email})`
    },
    onUpdateModelValue (newValue, role, contract) {
      this.$emit('update:modelValue', newValue, role, contract || null)
    },
  },
}
</script>
<template>
  <div class="form-section">
    <h1>{{ $gettext('Project team') }}</h1>
    <v-card>
      <v-card-text>
        <ChipsAutocomplete
          v-model="managersData"
          :label="$gettext('Project manager')"
          :item-value="item => item"
          :item-display="getItemDisplay"
          :items="users"
          hide-details
          @search="onSearch"
          @update:model-value="newValue => onUpdateModelValue(newValue, 'managers')"
        />
        <ChipsAutocomplete
          v-model="docControllersData"
          :label="$gettext('Doc control')"
          :item-value="item => item"
          :item-display="getItemDisplay"
          :items="users"
          hide-details
          @search="onSearch"
          @update:model-value="newValue => onUpdateModelValue(newValue, 'docControllers')"
        />
        <ChipsAutocomplete
          v-for="contract in activeContractsData"
          :key="contract.id"
          v-model="contract.managers"
          :label="`${$gettext('Contract lead')} ${contract.name}`"
          :item-value="item => item"
          :item-display="getItemDisplay"
          :items="users"
          hide-details
          @search="onSearch"
          @update:model-value="newValue => onUpdateModelValue(newValue, 'managers', contract)"
        />
      </v-card-text>
    </v-card>
  </div>
</template>
<style lang="scss" scoped>
.v-card-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
